/* * {
    box-sizing: border-box;
  } */
  
  .avatar {
    /* width: 5vw;
    height: 5vw; */
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    /* background-color: #2c303a;
    border: 2px solid #2c303a; */
    
    
  }
  .avatar img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  
  .avatar-group {
    display: flex;
  }
  .avatar-group .rtl {
    direction: rtl;
  }

  .avatar-group .rtl .avatar:hover:not(:last-of-type) {
    transform: translate(10px);
  }

  .avatar-group .avatar {
    margin-left: -20px; /* how  much it entered into another */
    transition: transform 0.3s ease;
    cursor: pointer;
  }

  .avatar-group .avatar :hover:not(:last-of-type) {
    transform: translate(-10px);
 }

  .avatar-group .hidden-avatars {
    /* width: 50px;
    height: 50px; */
    /* border-radius: 50%; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-left: 3px; */
    /*margin-right: 2px;*/ /* how  far the more is from others */
    /* background-color: #2c303a; */
    /* color: #fff; */
  }