/* Rectangle 4 */

.main-box {
    /* position: absolute; */
    /* width: 100vw;
    height: 100vh; */

    background: url('../../public/assets/images/5.jpg') #16307D;
    background-size: cover;
    background-blend-mode: multiply;

    /* background: #16307D; */
    /* opacity: 0.8; */

}

.b-screen {
    display: block;
}
.m-screen {
    display: none;
}

@media (max-width: 991.98px) {
    .b-screen {
      display: none;
    }
    .m-screen {
        display: block;
    }
}